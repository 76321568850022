<template>
  <b-modal
    :no-close-on-backdrop="true"
    v-model="sendRemainderModal"
    title="Send Reminder"
    title-class="text-black poppins font-18"
    hide-footer
    hide-header-close
    body-class="p-3"
  >
    <div slot="modal-title poppins">Pick Shift</div>
    <form>
      <div class="row">
        <div class="col-md-12 mb-3">
          <div class="d-flex gap-2">
            <div class="col-md-12">
              <div class="align-items-center d-flex gap-2">
                <div class="icon-wrapper mt-3 pt-1">
                  <img
                    src="@/assets/images/profile-manager/icon-12.png"
                    width="21"
                    alt=""
                  />
                </div>

                <div class="form-field__wrapper w-100">
                  <label class="text-dark"
                    >Select Vacant Shift Time
                    <span class="primaryColor">*</span></label
                  >
                  <select id="work_area" v-model="shiftId" class="form-select">
                    <option value="" disabled>Select Shift Time</option>
                    <option
                      v-for="(timeSlot, i) in timeSlots"
                      :key="i"
                      :value="timeSlot.shift_id"
                    >
                      {{
                        DateWithMonthName(timeSlot.from)
                      }}
                      -
                      {{ DateWithMonthName(timeSlot.to) }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-end p-3 pe-0">
        <button
          class="btn btn-outline-pink mx-3"
          type="button"
          @click="$emit('closeModal')"
        >
          Close
        </button>
        <SubmitButton
          class="mx-2"
          text="Send"
          :processing="processing"
          @clicked="sendNotificaiton()"
        />
      </div>
    </form>
  </b-modal>
</template>
<script>
export default {
  props: ["timeSlotsProp", "statusProp", "processingProp"],
  data() {
    return {
      timeSlots: this.timeSlotsProp,
      sendRemainderModal: this.statusProp,
      shiftId: "",
      processing: this.processingProp,
    };
  },
  watch: {
    timeSlotsProp: function (value) {
      this.timeSlots = value;
    },
    statusProp: function (value) {
      this.sendRemainderModal = value;
    },
    processingProp: function (value) {
      this.processing = value;
    },
  },
  methods: {
    sendNotificaiton() {
      if (this.shiftId == "") {
        this.triggerSwal("Please select any slot from drop down", "error");
        return;
      }
      this.$emit("send", this.shiftId);
    },
  },
};
</script>