<script>
    import { required } from "vuelidate/lib/validators";
    import VueMultiselect from "@/components/general/vue-multiselect";
    import Swal from "sweetalert2";

    export default {
    
        data() {
            return {
                assignShiftModal:false,
                employee_id: null,
                selectedEmployee:null,
                employees : {
                    data : [],
                    selected : [],
                    VueMultiselectOpened: false,
                    loading: false,
                    nextPageUrl: null,
                },
                shiftTimeSlot:{type:null,id:null},
                shiftsForRole :{},
                location : {},
                processing:false,
                length : 6
            };
        },
        validations:{
            employee_id: { required },
        },
        components: {
            VueMultiselect
        },
        props: {
            shift:{
                type: Object,
                default: () => {
                    return {}
                },
            },
            area_role:{
                type: Object,
                default: () => {
                    return {}
                },
            },
            currentLocation : {
                type: Object,
                default: () => {
                    return {}
                },
            }
        },

        watch: {
            shift: {
                handler(newValue){
                    if(newValue){
                        this.shiftsForRole = {...newValue};
                        this.location = {...newValue?.location};
                        this.fetchShiftSlotByAreaId()
                    }
                },
                deep: true,
                immediate: true,
            },
            selectedEmployee:{
                handler:function(newValue){
                    if(newValue != null){
                        this.employee_id = newValue.id;
                    }
                }
            },
              assignShiftModal:{
                handler:function(newValue){
                    if(newValue == false){
                        this.selectedEmployee = null;
                        this.employee_id = null;
                    }
                }
            },
        },

        computed : {
            loadMoreEmployees() {
                return !!this.employees.nextPageUrl;
            },
        },

        methods: {
            fetchMembersByRole()
            {
                let url =  '/employee/team-member/area-role';
                if (this.employees.nextPageUrl) {
                    url = this.employees.nextPageUrl;
                }
                this.$axios.get(url,{
                    params: {slot_id:this.shiftTimeSlot.id, location_id:this.location.id , area_role_id : this.area_role.id , length : this.length , employee_status:'active_employee'}}).then(response => {
                    let result = response.data.payload;
                    if (url == "/employee/team-member/area-role") this.employees.data = [];
                    result.data.forEach((employee) => {
                        this.employees.data.push({id : employee.id , name : employee.user.name , email : employee.user.email})
                    });
                    this.employees.nextPageUrl = result.next_page_url;
                }).catch(error => {
                    this.handleErrorResponse(error.response, "error");
                });
            },

            confirmOverTimeShift() {
                Swal.fire({
                    title: "Are you sure?",
                    text: "You want to assign shifts to this user, the user has already scheduled 40 hours in a week.",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#70C213",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes, Accept",
                }).then((result) => {
                    if (result.value) {
                        this.submitShift();
                    }
                });
            },

            checkCounts()
            {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.$axios
                .get("counts-stats/index", {
                    params: {employee_id : this.employee_id, shift_id: this.shift.id }
                })
                .then((response) => {
                    let totalHours = response.data.payload;
                    if(totalHours > 40)
                    {
                        this.confirmOverTimeShift();
                    }else{
                        this.submitShift();
                    }
                }).catch(error => {
                    this.handleErrorResponse(error.response, "error");
                });
            },

            submitShift()
            {   
                if (this.processing) {
                    return;
                }
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.processing = true;
                this.$axios.post('shift/employee/select-shift', {
                employee_id:this.employee_id,
                shift_id:this.shift.id, 
                area_role_id:this.area_role.id,
                slot_id:this.shiftTimeSlot.id,
                status:'Accepted'
            })
                .then(response => {
                    let data = response.data;
                    this.assignShiftModal=false;
                    this.processing = false;
                    this.triggerSwal('Shift Assigned Successfully', "success");
                })
                .catch(error => {
                    this.handleErrorResponse(error.response, "error");
                })
                .finally(() => {
                    this.processing = false;
                    this.assignShiftModal=false;
                    this.employee_id = null;
                    this.$v.$reset();
                });
            },

            fetchShiftSlotByAreaId() {
                this.$axios.get("shift-time-slots/area-role",{
                    params:{
                        shift_id:this.shift.id,
                        area_role_id:this.area_role.id,
                        empty_slot:true,
                        }}

                ).then((response) => {
                let data = response.data.payload;
                this.shiftsForRole.shift_time_slots = data;
                this.shiftTimeSlot = data[0];

                }).catch(function (error){
                this.handleErrorResponse(error.response, "error");
                });   
            },

            fetchEmployeesOnReachingEnd() {
                this.fetchMembersByRole();
            },
            changeTimeZone(date)
            {
                if(Object.entries(this.currentLocation).length > 0){
                    let zone = this.currentLocation.utc[0];
                    return this.changeDateTimeTimeZone(date,zone);
                }else{
                    return this.DateWithMonthName(date , 'DD-MM-YYYY hh:mm A');
                }
            },
        },
        middleware: "authentication",
    };
</script>

<template>
    <div>
        <b-modal id="modal-assignShift"  scrollable v-model="assignShiftModal" content-class="shadow" size="lg" title="Assign Shift" title-class="text-black poppins font-18" hide-footer body-class="p-3 pb-5" no-close-on-backdrop no-close-on-esc hide-header-close >
                <b-form inline>
                    <div class="row">
                        <div class="col-md-6">
                            <span class="text-muted" for="inline-form-input-name">Location</span>
                            <input type="text"  class="form-control mt-1" v-model="location.name" disabled>
                        </div>

                        <div class="col-md-6">
                            <span class="text-muted" for="inline-form-input-name" v-if="Object.entries(area_role).length">Selected Role/Position</span>
                            <input type="text"  class="form-control mt-1" v-model="area_role.name" disabled>
                        </div>

                        <div class="col-md-6" >
                            <span class="text-muted" for="inline-form-input-username">Time Slot</span>
                             <select  v-model="shiftTimeSlot" class="form-select mt-1" disabled>
                                <option :value="time_slot" v-for="(time_slot,index) in shiftsForRole.shift_time_slots" :key="index" >{{changeTimeZone(time_slot.from)}} - {{changeTimeZone(time_slot.to)}}</option>
                            </select>
                        </div>

                        <div class="col-md-6">
                            <span class="text-muted" for="inline-form-input-name">Employee</span>
                            <vue-multiselect
                                placeholder="Select an Employee"
                                :class="{'is-invalid': $v.employee_id.$error}"
                                v-model="selectedEmployee"
                                hide-selected
                                :load-more="loadMoreEmployees"
                                :loading="employees.loading"
                                :options="employees.data"
                                @on-open="fetchMembersByRole"
                                :show-no-results="true"
                                @on-reaching-end="fetchEmployeesOnReachingEnd"
                                track-by="id"
                                label="name"
                                :show-labels="false"
                            >
                            <!-- <template v-slot:optionSlot="{option}">
                                <span class="option__desc"><span class="option__title">{{ option.user.email }}</span>
                                </span>
                            </template> -->
                            </vue-multiselect>
                            <div v-if="$v.employee_id.$error" class="invalid-feedback" >
                                <span v-if="!$v.employee_id.required">Employee is required.</span>
                            </div>
                        </div>
                    
                        <div class="col-md-4 mt-4 d-flex">
                             <SubmitButton :processing="processing" class="mx-2 w-100" text="Assign" @clicked="checkCounts" />
                             <button type="button" class="btn btn-outline-pink mx-3" @click="assignShiftModal=false; $v.$reset();"> Close </button>
                        </div>
                    </div>
                </b-form>
        </b-modal>
    </div>
</template>
