<template>
  <div>
    <div class="table-responsive">
     
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col" class="text-center bg-light">
              <button @click="$emit('changeSlider', 'previous')" class="btn">
                <i class="fas fa-chevron-left"></i>
              </button>
              {{ startDate }} - {{ endDate }}
              <button @click="$emit('changeSlider', 'next')" class="btn">
                <i class="fas fa-chevron-right"></i>
              </button>
            </th>
            <th
              v-for="(week, index) in weeks"
              scope="col"
              class="text-center bg-light"
              :key="index"
            >
              {{ week }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(timelineKey, name) in timelines" :key="name">
            <td class="d-flex gap-2 align-items-center">
              <span>
                <img
                  :src="timelines[name][0].employee.user.avatar_path"
                  alt
                  width="42"
                  height="42"
                  class="img-fluid rounded-circle"
                />
              </span>
              <span
                >{{ name }} <br />Total Shifts: {{ timelineKey.length }}</span
              > 
              <span>
               <strong>( {{ getSafe(()=>timelines[name][0].shift.location.time_zone.abbr , "Not Available")}} )</strong>
              </span>
            </td>
            <!-- <template v-for="(timeline, key) in timelineKey"> -->
            <td class="p-0" v-for="week in weeks" :key="week">
              <div
                :class="{
                  'shiftActive py-3 ps-2': true,
                }"
                v-if="getDataFromTimeline(week, timelineKey) != null"
              >
                <span class="d-block ps-2 fw-bold">{{
                  getDataFromTimeline(week, timelineKey).shift.name
                }}</span>
                <span class="px-2"
                  >{{
                    customFormatDate(
                      getDataFromTimeline(week, timelineKey).from,
                      "hh:mm A"
                    )
                  }}
                  to
                  {{
                    customFormatDate(
                      getDataFromTimeline(week, timelineKey).to,
                      "hh:mm A"
                    )
                  }}</span
                >
              </div>
             
            </td>
          
          </tr>
        </tbody>
      </table>
      <AlertWidget v-if="Object.entries(timelines).length == 0"></AlertWidget>
    </div>
  </div>
</template>

<script>
import AlertWidget from "@/components/general/AlertWidget.vue";
import timeSlot from "../../../../../mixins/timeSlot";

export default {
  name: "EmployeeWeekView",
  props: {
    timelines: {
      type: [Array, Object],
      default: () => {},
    },
    weeks: {
      type: [Array],
      default: () => {},
    },
    startDate: {
      type: [String],
      default: () => {},
    },
    endDate: {
      type: [String],
      default: () => {},
    },
  },
  components: { AlertWidget },
  data() {
    return {
      sendRemainderModal:false,
    };
  },

  watch: {},

  mounted() {},

  methods: {
    sendRemainder(){
      this.sendRemainderModal = true;
    }
  },
  mixins:[timeSlot],
};
</script>