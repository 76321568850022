<template>
  <div>
    <div class="table-responsive">
      <table class="table table-bordered" id="timeView">
        <thead>
          <tr>
            <th scope="col" class="text-center bg-light" style="min-width: 200px">
              <button @click="$emit('changeSlider','previous')" class="btn">
                <i class="fas fa-chevron-left"></i>
              </button>
              {{ dayDate }} 
              <button @click="$emit('changeSlider','next')" class="btn">
                <i class="fas fa-chevron-right"></i>
              </button>
            </th>
            <th
              v-for="(hour, index) in hours"
              scope="col"
              class="text-center bg-light"
              :key="index"
            >{{hour}}</th>
          </tr>
        </thead>
        <tbody class="table-nowrap">
          <tr  v-for="(timelineKey, indexOfTimelines) in Object.entries(timelines)" :key="indexOfTimelines">
            <td class="d-flex gap-2 align-items-center" style="min-width: 200px">
              <span>
              
                <img
                  :src="timelineKey[1][0].employee.user.avatar_path"
                  alt
                  width="42"
                  height="42"
                  class="img-fluid rounded-circle"
                />
              </span>
              <span style="min-width: 200px"
                >{{ timelineKey[0] }} <br />Total Shifts: {{ timelineKey[1].length }}
              <!-- <br/>Wrok Area: Kitchen <br/> Rquired Count of chief: 02 -->
              </span>
            </td>
            
              <td v-for="(hour, index) in hours" :key="index">
                <span v-if="getTimeLine(hour ,timelineKey[1] , index) !=null"
                 
                  :class="{
                    ' p-1 ps-2': true}"
                >
                  <span class="d-block ps-2 fw-bold">{{
                    getSafe(()=> getTimeLine(hour ,timelineKey[1] , index , true , indexOfTimelines , timelineKey[0]).res.shift.name )
                  }}</span>
                  <span class="px-2"
                    >{{ getSafe(()=> customFormatDate(getTimeLine(hour ,timelineKey[1] ).res.from, "hh:mm A") )}} to
                    {{ getSafe(()=> customFormatDate(getTimeLine(hour ,timelineKey[1] ).res.to, "hh:mm A")) }}</span
                  >
                  <br>
                  <span class="text-capitalize primaryColor px-2">{{ getSafe(()=> getTimeLine(hour ,timelineKey[1] , index , true , indexOfTimelines , timelineKey[0]).res.area_role.name)
                  }}</span>
                </span>
                {{ getSafe(()=> getToTimeLine(hour ,timelineKey , index) )}}
              </td>
            <!-- <td
              v-for="(time, index) in 24 - timelineKey.length"
              :key="'indd' + index"
            ></td> -->
          </tr>
        </tbody>
      </table>
      <AlertWidget v-if="Object.entries(timelines).length == 0"></AlertWidget>
    </div>
  </div>
</template>

<script>
import AlertWidget from "@/components/general/AlertWidget.vue";

export default {
  name: "EmployeeDayView",
  classes:[],
   props: {
    timelines: {
      type: [Array,Object],
      default: () => {},
    },
    hours: {
      type: [Array],
      default: () => {},
    },
    dayDate: {
      type: [String],
      default: () => {},
    },
  },

    components: { AlertWidget},
    watch:{
       timelines:{
        handler:function(value){
          this.updateClasses();
           this.timelines = value;
        },
        // immediate: true,
        // deep:true, 
           
       },

    },

  data() {
    return {
      
      repeatedIndex:-1,
    };
  },

  // created() {

  //   this.updateClasses();
   
    
  // },
  methods: {
   updateClasses()
   {
    let updatedClasses = [];
    this.classes = [];
    if(Object.entries(this.timelines).length != 0)
    {
    // console.log(this.timelines);
    for(let i = 0; i<this.hours.length; i++){
      updatedClasses.push({shiftActive:false});
    }
       let self = this;
    let data = {
    };
        

       Object.entries(this.timelines).forEach((element) => {
        // element[0] = self.classes 
        data[element[0]] = updatedClasses;
        self.classes.push(data);
       
        data = {};
        // console.log("this is element 2 " ,  element[0]);
    });
   
    }
    
   },
    checkColumnINdex(timeline){
      let from = this.customFormatDate(timeline.from, 'hha');
      let to = this.customFormatDate(timeline.to, 'hha');
      var fromIndex = this.hours.indexOf(from);
      var toIndex = this.hours.indexOf(to);
      // let data = this.getCellValues(fromIndex,toIndex);
      //   console.log('true',from,to,data);
      // if(data == from || data == to){
      //   console.log('ssss');
      //   return true;
      // }else{
      //   return false;
      // }
    },
    getCellValues(ind) {
      var table = document.getElementById('timeView');
        // let fromValue = table.rows[0].cells[toInd+1].innerHTML;
        let toValue = table.rows[0].cells[ind+1].innerHTML;
      // console.log(fromValue,toValue);
      return toValue;
      // return [fromValue,toValue];
    // for (var r = 0, n = table.rows.length; r < n; r++) {
    //     for (var c = 1, m = table.rows[r].cells.length; c < m; c++) {
    //     }
    // }
},
getClasses()
{
     if(typeof this.classes != 'undefined'){
      return this.classes
     }
     return null;
},
getTimeLine(hour , timeline , index = null , run = false , indexOfTimelines = null , name = null){
  let self = this;
  var res = timeline.find(obj => { return self.customFormatDate(obj.from , 'hha') == hour})
  if(typeof res != 'undefined'){
    return {res , index}
  }
  return null;
},
getToTimeLine(hour , timeline, index = null)

{
  let self = this;
  var res = timeline.find(obj => { return self.customFormatDate(obj.to , 'hha') == hour})
},
checkShiftActiveClass(from , to , index)
{
  if(from !=null){
    if(from.index > index)
    return true;

  }
  else if(to !=null && to.index < index) 
  {
     return true;
  }
  return false;

}
  },
};
</script>