<script>
import Layout from "@/views/layouts/static/main"
import calendar_component from "./calendar_component.vue"

export default {
  
  components: {
    calendar_component,
    Layout,
  },

  middleware: "authentication",
};
</script>

<template>
  <Layout :dynamicClass="'container-fluid'">
    <calendar_component pageTitle="Shifts Schedule" />
  </Layout>
</template>
