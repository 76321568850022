<template>
  <div>
    <sendRemainderVue
      :timeSlotsProp="timeSlots"
      :processingProp="processing"
      :statusProp="sendRemainderModal"
      @closeModal="sendRemainderModal = false"
      @send="sendNotificaiton"
    />
    <div class="table-responsive">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col" class="text-center bg-light">
              <button @click="$emit('changeSlider', 'previous')" class="btn">
                <i class="fas fa-chevron-left"></i>
              </button>
              {{ startDate }} - {{ endDate }}
              <button @click="$emit('changeSlider', 'next')" class="btn">
                <i class="fas fa-chevron-right"></i>
              </button>
            </th>
            <th
              v-for="(week, index) in weeks"
              scope="col"
              class="text-center bg-light"
              :key="index"
            >
              {{ week.date }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="employee in allEmployees" :key="employee.id">
            <td class="d-flex gap-2 align-items-center" align="center" >
              <span>
                <img
                  :src="employee.avatar_path"
                  alt
                  width="42"
                  height="42"
                  class="img-fluid rounded-circle"
                />
              </span>
              <span>{{ employee.name }}</span>
            </td>
            <!-- <template v-for="(timeline, key) in timelineKey"> -->
            <td
              :class="['p-0' , {'active': getDataFromTimeline(
                    week.date,
                    getSafe(() => employee.shifts, [])
                  ) != null }]"
              v-for="(week, index) in weeks"
              :key="index"
              @click="getDataFromTimeline(
                    week.date,
                    getSafe(() => employee.shifts, [])
                  ) != null?getTimeSlots(employee.id , getDataFromTimeline(
                    week.date,
                    getSafe(() => employee.shifts, [])
                  )):''"
            >
              <span
                v-if="
                  getDataFromTimeline(
                    week.date,
                    getSafe(() => employee.shifts, [])
                  ) != null
                "
              >
              </span>
            </td>
          </tr>
          <tr v-if="allEmployees.length == 0">
            <td colspan="12">
             <AlertWidget ></AlertWidget>
            </td>
          </tr>
          <tr>
            <td colspan="12">
              <pagination :paginationProp="paginationData" @next="fetchVacantEmployee" />
            </td>
          </tr>
        </tbody>
      </table>
      
    </div>
  </div>
</template>

<script>
import AlertWidget from "@/components/general/AlertWidget.vue";
import sendRemainderVue from "./sendRemainder.vue";
import timeSlot from "../../../../../mixins/timeSlot";
import pagination from "../reuseableComponents/pagination.vue";
export default {
  name: "VacntEmployeeWeekView",
  props: {
    weeks: {
      type: [Array],
      default: () => {},
    },
    startDate: {
      type: [String],
      default: () => {},
    },
    endDate: {
      type: [String],
      default: () => {},
    },
    searchAbleDataProp:{
      type:String,
      default:null,
    }
  },
  components: { AlertWidget, sendRemainderVue , pagination },
  data() {
    return {
      sendRemainderModal: false,
      allEmployees: [],
      timeSlots: [],
      selectedUserId: null,
      shiftId: null,
      processing: false,
      paginationData:null,
    };
  },

  watch: {
    allEmployees: function (newVal) {
      this.allEmployees = newVal;
    },
    startDate:function(){
     this.fetchVacantEmployee(); 
    },
    searchAbleDataProp:function(newVal)
    { 
         this.fetchVacantEmployee();
    }
  },

  mounted() {
    this.fetchVacantEmployee();
  },

  methods: {
    async fetchVacantEmployee( url = "/shift/employee/get-vacant-employees") {
      try {
        let response = await this.$axios.get(
          url,
          {
            params: {
              from: this.customFormatDate(this.startDate, "DD-MM-YYYY"),
              to: this.customFormatDate(this.endDate, "DD-MM-YYYY"),
              searchAbleName: this.searchAbleDataProp,
            },
          }
        );
        this.allEmployees = response.data.payload.data;
        this.paginationData = response.data.payload
      } catch (error) {
        console.log(error);
      }
    },
    sendRemainder() {
      this.sendRemainderModal = true;
    },
     getTimeSlots(user_id , shift) {
        this.timeSlots = shift.shift_time_slots;
        this.selectedUserId = user_id;
        this.sendRemainderModal = true;
    },
    async sendNotificaiton(shiftId) {
      this.shiftId = shiftId;
      try {
        this.processing = true;
        let response = await this.$axios.post(
          "/shift/employee/notify-employee",
          { shiftId: this.shiftId, userId: this.selectedUserId }
        );
        if (response.data.status) {
          this.triggerSwal(response.data.message, "success");
        } else {
          this.triggerSwal(response.data.message, "error");
        }
        this.processing = false;
        this.sendRemainderModal = false;
      } catch (error) {
        this.processing = false;
        console.log(error);
      }
    },
  },
  mixins: [timeSlot],
};
</script>