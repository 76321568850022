<template>
  <div>
    <VacantEmpWeekView v-if="viewType == 'empWeek'" @changeSlider="changeSlider" :weeks="weeks" :startDate="startDate" :endDate="endDate" :timelines="timelines" :searchAbleDataProp="searchAbleData" />
   </div>
</template>

<script>
import AlertWidget from "@/components/general/AlertWidget.vue";
import VacantEmpWeekView from "./vacantEmpWeekView.vue";
import moment from 'moment';
export default {
  props: {
    
    searchAbleDataProp:{
      type:String,
      default:null,
    }
  },
  components: {VacantEmpWeekView},
  data() {
    return {
      viewType:"empWeek",
      timelines: [],
      firstDate: null,
      lastDate: null,
      startDate: null,
      endDate: null,
      weeks: [],
      currentDate: new Date(),
      params: {
        shift_id: null,
        area_role_id: null,
        group_by: true,
        availability : null,
      },
      conditionalDay:6,
      searchAbleData:null
    };
  },

  watch: {

    searchAbleDataProp:function (newVal){
      this.searchAbleData = newVal;
    }
  },

  mounted() {
    this.setSlider("current");
  },

  methods: {
    setSlider(direction) {
      this.slider = direction;
      let dt = this.weekSlider(direction);
      this.params.from = this.customFormatDate(dt[0] , "DD-MM-YYYY");
      this.params.to = this.customFormatDate(dt[1] , "DD-MM-YYYY");
  
    },

    getDailyDates(direction){
      // console.log("this is current date"+this.currentDate);
      if (direction == "next") {
        var next_date = moment(this.startDate).add(1, 'days');
        this.startDate = this.DateWithMonthName(next_date , 'DD MMM YY');
      return [next_date , next_date];
      }
      if(direction == 'previous'){
        var previous_date = moment(this.startDate).subtract(1 , 'days')
        this.startDate = this.DateWithMonthName(previous_date , 'DD MMM YY');
        return [previous_date , previous_date];
      }
      else{
         this.startDate = this.DateWithMonthName(new Date() , 'DD MMM YY');
         return [this.startDate , this.startDate]  
      }
      

    },

    weekSlider(direction) {
      this.currentDate = new Date(this.currentDate);
      let dayNumber;
      if(this.viewType == "empWeek"){
         dayNumber = this.getDayNumberByName("Monday");
      }else{
        let dates = this.getDailyDates(direction);
        return dates;
        // dayNumber = this.currentDate.getDay();
        // console.log(dayNumber,this.currentDate.getDate());
      }
      var first1 =
        this.currentDate.getDate() - this.currentDate.getDay() + dayNumber;

      var last1 = first1 + this.conditionalDay; // last day is the first day + this.conditionalDay
      this.firstDate = new Date(this.currentDate.setDate(first1));
      this.lastDate = new Date(this.currentDate.setDate(last1));
      if (direction == "current") {
        this.currentDate = new Date();
      }
      if (direction == "next") {
        this.currentDate = this.lastDate;
      }
      if (direction == "previous") {
        this.currentDate = this.firstDate.setDate(this.firstDate.getDate() - this.conditionalDay);
      }
      var curr = new Date(this.currentDate); // get current date
      var first = curr.getDate() - curr.getDay() + dayNumber;

      var last = first + this.conditionalDay; // last day is the first day + this.conditionalDay
      var firstDate = new Date(curr.setDate(first));
      var lastdate = new Date(curr.setDate(last));

      this.startDate = this.DateWithMonthName(firstDate , 'DD MMM YY');
      this.endDate = this.DateWithMonthName(lastdate , 'DD MMM YY');
      this.weeks = [];
      this.weeks = this.getDates(firstDate, lastdate);

      return [firstDate, lastdate];
    },

    getDates(startDate, endDate) {
      //first the function and variable definitions
      let dates = [];
      const addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };
      //now our Sunday check
      let currDate = startDate;
      // if (currDate.getDay() > 0) {
      //   console.log("not a sunday...adjusting");
      //   currDate.setDate(currDate.getDate() - currDate.getDay());
      // }

      while (currDate <= endDate) {
        dates.push({date:this.DateWithMonthName(currDate, "ddd DD") , value:this.DateWithMonthName(currDate, "DD-MM-YYYY")});
        currDate = addDays.call(currDate, 1);
      }
      return dates;
    },
    changeSlider(data){
      this.timelines = {};
      this.setSlider(data)
    }
  },
};
</script>